<template>
    <div id="firmaekle">
        <b-container>
            <b-row>
                <b-col sm="12">
                    <b-form @submit.prevent="ekle" class="text-left mt-5">
                        <b-form-group
                            id="input-group-1"
                            label="Belediye Ünvan: (zorunlu)"
                            label-for="input-1">
                            <b-form-input
                            id="input-1"
                            v-model="form.user_adsoyad"
                            required
                            type="text"
                            placeholder="Belediyenin ünvanı girin."
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group
                            id="input-group-2"
                            label="Tel: (zorunlu)"
                            label-for="input-2">
                            <b-form-input
                            id="input-2"
                            v-model="form.user_tel"
                            required
                            type="number"
                            placeholder="Telefon numarasını girin."
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group
                            id="input-group-3"
                            label="E-Posta: (zorunlu)"
                            label-for="input-3">
                            <b-form-input
                            id="input-3"
                            v-model="form.user_eposta"
                            type="email"
                            placeholder="E-posta adresini girin."
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group
                            id="input-group-4"
                            label="Şifre: (zorunlu)"
                            label-for="input-4">
                            <b-form-input
                            id="input-4"
                            v-model="form.user_sifre"
                            placeholder="Şifre girin."
                            ></b-form-input>
                        </b-form-group>

                        <b-button block v-if="!$route.params.id" type="submit" variant="primary" class="float-right mb-5">Ekle</b-button>
                        <b-button block v-if="$route.params.id" type="submit" variant="primary" class="float-right mb-5">Düzenle</b-button>
                    </b-form>
                </b-col>    
            </b-row>
        </b-container>
    </div>
</template>

<script>

export default {
    methods : {
        ekle(){
            if(this.$route.params.id){
                this.$store.dispatch("userEdit", {
                    user_adsoyad : this.form.user_adsoyad,
                    user_tel : this.form.user_tel,
                    user_eposta : this.form.user_eposta,
                    user_sifre : this.form.user_sifre,
                    user_plaka : this.form.user_plaka,
                    user_id : this.$route.params.id,
                })
                .then(r => {
                    if(r.status){
                        this.$router.push("/belediyeler")
                    }
                })
            }else{
                console.log(this.form);
                this.$store.dispatch("userAdd", {
                    user_adsoyad : this.form.user_adsoyad,
                    user_tel : this.form.user_tel,
                    user_eposta : this.form.user_eposta,
                    user_plaka : this.form.user_plaka,
                    user_sifre : this.form.user_sifre,
                    user_rutbe: 2
                })
                .then(r => {
                    if(r.status){
                        this.$router.push("/belediyeler")
                    }
                })
            }
            
        }
    },

    mounted(){
        // if(this.$route.params.id){
        //     this.$store.dispatch("userFind", this.$route.params.id)
        //     .then(r => {
        //         console.log(r)
        //         this.form = r;
        //     });
        // }else {
        //     this.form = [];
        // }
    },

    computed: {
        form(){
            var deger = [];
            if(this.$route.params.id){
                deger = this.$store.state.users.filter(c => c.user_id == this.$route.params.id)[0]
                // this.$store.dispatch("userFind", this.$route.params.id)
                // .then(r => {
                //     deger = r;
                // });
            }
            return deger;
        }
    }
}
</script>